package de.ohhhmhhh.frontend.admin.ui.screens.user.edit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import de.ohhhmhhh.backend.models.model.user.UserRoleEntity
import de.ohhhmhhh.frontend.admin.helper.Formats
import de.ohhhmhhh.frontend.admin.helper.label
import de.ohhhmhhh.frontend.admin.helper.today
import de.ohhhmhhh.frontend.admin.ui.components.buttons.Button
import de.ohhhmhhh.frontend.admin.ui.components.buttons.ButtonType
import de.ohhhmhhh.frontend.admin.ui.components.container.Page
import de.ohhhmhhh.frontend.admin.ui.components.container.Row
import de.ohhhmhhh.frontend.admin.ui.components.dialogs.ConfirmationDialog
import de.ohhhmhhh.frontend.admin.ui.components.inputs.CheckboxInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.DateInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.SelectInput
import de.ohhhmhhh.frontend.admin.ui.components.inputs.TextInput
import de.ohhhmhhh.frontend.admin.ui.components.other.ProfileImageIcon
import de.ohhhmhhh.frontend.admin.ui.components.other.Separator
import de.ohhhmhhh.frontend.admin.ui.components.other.Spacer
import de.ohhhmhhh.frontend.admin.ui.components.other.SuccessMessage
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import kotlinx.datetime.plus
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

@Composable
fun UserEditPageContent(
    state: UserEditState,
    generateCode: () -> Unit,
    setCode: (String?) -> Unit,
    setPassword: (String) -> Unit,
    setCommenter: (Boolean) -> Unit,
    setEnabled: (Boolean) -> Unit,
    setEmailAddress: (String) -> Unit,
    setImageUrl: (String) -> Unit,
    setFirstName: (String) -> Unit,
    setLastName: (String) -> Unit,
    setDisplayName: (String?) -> Unit,
    setCompanyName: (String?) -> Unit,
    setAddress: (String) -> Unit,
    setAddressSupplement: (String?) -> Unit,
    setCity: (String) -> Unit,
    setPostalCode: (String) -> Unit,
    setCountryCode: (String) -> Unit,
    setInstagram: (String?) -> Unit,
    setRole: (UserRoleEntity) -> Unit,
    setBirthdate: (LocalDate?) -> Unit,
    setDeletionDate: (LocalDate?) -> Unit,
    setNotificationNewsletter: (Boolean) -> Unit,
    setNotificationInstagram: (Boolean) -> Unit,
    back: () -> Unit,
    login: () -> Unit,
    save: () -> Unit,
    delete: () -> Unit,
    activities: () -> Unit,
    subscriptions: () -> Unit,
    resetPassword: () -> Unit
) {
    val showDeleteConfirmation = remember { mutableStateOf(false) }

    Page("Nutzer " + if (state.id == null) "erstellen" else "bearbeiten", onBack = back) {
        if (state.id != null) {
            Row({ classes("distribute") }) {
                ProfileImageIcon(state.imageUrl, state.id, setImageUrl)
                TextInput(
                    title = "Erstellt",
                    value = state.created?.format(Formats.Instant.dateTime),
                )
                TextInput(
                    title = "Aktualisiert",
                    value = state.updated?.format(Formats.Instant.dateTime),
                    placeholder = "-",
                )
                TextInput(
                    title = "Letzter Login",
                    value = state.lastLogin?.format(Formats.Instant.dateTime),
                    placeholder = "-",
                )
            }
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput("Vorname", state.firstName) { setFirstName(it) }
            TextInput("Nachname", state.lastName) { setLastName(it) }
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput("Anzeigename", state.displayName) { setDisplayName(it.ifBlank { null }) }
            TextInput("Unternehmen", state.companyName) { setCompanyName(it.ifBlank { null }) }
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput("E-Mail-Adresse", state.emailAddress) { setEmailAddress(it) }
            DateInput("Geburtsdatum", state.birthdate) { setBirthdate(it) }
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput("Straße & Hausnummer", state.address) { setAddress(it) }
            TextInput("Adresszusatz", state.addressSupplement) { setAddressSupplement(it.ifBlank { null }) }
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput("Stadt", state.city) { setCity(it) }
            TextInput("Postleitzahl", state.postalCode) { setPostalCode(it) }
        }
        Spacer()
        Row({ classes("distribute") }) {
            Div()
            TextInput("Landeskürzel", state.countryCode) { setCountryCode(it) }
        }
        Spacer()
        Row({ classes("distribute") }) {
            TextInput("Instagram", state.instagram) { setInstagram(it.ifBlank { null }) }
            Div {
                Label(attrs = { classes("form-label") }) {
                    Text("Benachrichtungen")
                }
                Row({
                    style { marginTop(5.pt) }
                }) {
                    CheckboxInput("Newsletter", state.notificationNewsletter, onChange = setNotificationNewsletter)
                    CheckboxInput("Instagram", state.notificationInstagram, onChange = setNotificationInstagram)
                }
            }
        }
        Spacer()
        Row({
            classes("distribute")
            style { alignItems(AlignItems.End) }
        }) {
            TextInput(
                "Registrierungscode",
                state.code,
                onChange = if (state.code == null) null else setCode,
            )
            CheckboxInput(
                "Registrierung abgeschlossen",
                state.code == null,
                onChange = { if (it) setCode(null) else generateCode() }
            )
        }
        Spacer()
        Row({ classes("distribute") }) {
            SelectInput("Rolle", UserRoleEntity.entries.map { it.label() }, state.role.label()) { v ->
                setRole(UserRoleEntity.entries.first { it.label() == v })
            }
            TextInput("Passwort", state.password) { v ->
                setPassword(v)
            }
        }
        Spacer()
        if (state.id != null) {
            Div({ if (state.deletionDate != null) classes("alert", "alert-danger") }) {
                Row({
                    classes("distribute")
                    style { alignItems(AlignItems.End) }
                }) {
                    val editable by remember(state.deletionDate) { mutableStateOf(state.deletionDate != null) }
                    DateInput("Löschdatum", state.deletionDate, onSelect = if (!editable) null else { d ->
                        setDeletionDate(d)
                    })
                    CheckboxInput(
                        "Löschung beantragt",
                        state.deletionDate != null
                    ) {
                        setDeletionDate(if (it) LocalDate.today().plus(1, DateTimeUnit.MONTH) else null)
                    }

                }
            }
        }
        Spacer()
        Row({ classes("distribute") }) {
            CheckboxInput(
                "Darf kommentieren",
                state.commenter
            ) {
                setCommenter(it)
            }
        }
        Spacer()
        Separator()
        Spacer()
        Row {
            if (state.id == null) {
                Button("Erstellen", ButtonType.HIGHLIGHT, { classes("ms-auto") }) { save() }
            } else {
                Button(
                    title = "Löschen",
                    onClick = { showDeleteConfirmation.value = true }
                )
                Button(
                    title = "Aktivitäten",
                    attrs = { classes("ms-auto") },
                    onClick = { activities() }
                )
                Button(
                    title = "Abonnements",
                    onClick = { subscriptions() }
                )
                Button(
                    title = "Passwort zurücksetzen",
                    onClick = { resetPassword() }
                )
                Button(
                    title = "Als Nutzer einloggen",
                    onClick = { login() }
                )
                Button(if (state.enabled) "Deaktivieren" else "Aktivieren") {
                    setEnabled(!state.enabled)
                }
                Button(
                    title = "Speichern",
                    type = ButtonType.HIGHLIGHT,
                    onClick = { save() }
                )
            }
        }
    }

    if (showDeleteConfirmation.value) {
        ConfirmationDialog(
            "Nutzer löschen",
            "Bist du sicher, dass diesen Nutzer löschen möchtest?",
            showDeleteConfirmation,
            delete
        )
    }

    if (state.saved != null) {
        val mutableState = remember(state.saved) { mutableStateOf(true) }
        SuccessMessage("Nutzer gespeichert", mutableState)
    }
}