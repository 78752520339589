package de.ohhhmhhh.backend.models.model.user

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserEntity(
    @SerialName("id")
    val id: String,
    @SerialName("enabled")
    val enabled: Boolean,
    @SerialName("imageUrl")
    val imageUrl: String?,
    @SerialName("code")
    val code: String?,
    @SerialName("password")
    val password: String,
    @SerialName("commenter")
    val commenter: Boolean,
    @SerialName("firstName")
    val firstName: String,
    @SerialName("lastName")
    val lastName: String,
    @SerialName("displayName")
    val displayName: String?,
    @SerialName("companyName")
    val companyName: String?,
    @SerialName("birthdate")
    val birthdate: LocalDate?,
    @SerialName("emailAddress")
    val emailAddress: String,
    @SerialName("addressLine1")
    val address: String,
    @SerialName("addressLine2")
    val addressSupplement: String?,
    @SerialName("adminArea1")
    val city: String,
    @SerialName("adminArea2")
    val state: String?,
    @SerialName("postalCode")
    val postalCode: String,
    @SerialName("countryCode")
    val countryCode: String,
    @SerialName("role")
    val role: UserRoleEntity,
    @SerialName("instagramUsername")
    val instagram: String?,
    @SerialName("lastLogin")
    val lastLogin: Instant?,
    @SerialName("created")
    val created: Instant,
    @SerialName("updated")
    val updated: Instant?,
    @SerialName("deletionDate")
    val deletionDate: LocalDate?,
    @SerialName("notificationNewsletter")
    val notificationNewsletter: Boolean,
    @SerialName("notificationInstagram")
    val notificationInstagram: Boolean,
)
