package de.ohhhmhhh.frontend.admin.di

import com.benasher44.uuid.uuid4
import de.ohhhmhhh.backend.sdk.OMServices
import de.ohhhmhhh.frontend.admin.helper.AuthenticationManager
import de.ohhhmhhh.frontend.admin.helper.Constants.BACKEND_URL
import de.ohhhmhhh.frontend.admin.helper.CurrentUserProvider
import de.ohhhmhhh.frontend.admin.navigation.Navigator
import de.ohhhmhhh.frontend.admin.navigation.Router
import de.ohhhmhhh.frontend.admin.navigation.Screen
import de.ohhhmhhh.frontend.admin.navigation.stack
import de.ohhhmhhh.frontend.admin.ui.screens.article.edit.ArticleEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.article.list.ArticleListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.asset.AssetListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.category.CategoryEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.comment.edit.CommentEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.comment.list.CommentListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.episode.edit.EpisodeEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.episode.list.EpisodeListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.invoice.InvoiceListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.landing.LandingEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.report.ReportListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.team.TeamEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.text.edit.TextEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.text.list.TextListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.activity.UserActivityViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.edit.UserEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.list.UserListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.user.subscriptions.UserSubscriptionViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.edit.VoucherEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.info.VoucherCheckoutInformationViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.voucher.list.VoucherListViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.workout.edit.WorkoutEditViewModel
import de.ohhhmhhh.frontend.admin.ui.screens.workout.list.WorkoutListViewModel
import kotlinx.browser.window
import org.koin.dsl.module

val Module = module {
    single {
        val stack = (Router(window.location.pathname) ?: Screen.UserList).stack()
        Navigator(stack)
    }
    single { AuthenticationManager() }
    single {
        OMServices(
            backendUrl = BACKEND_URL,
            tokenProvider = get<AuthenticationManager>(),
            callIdProvider = {
                uuid4().toString().also { console.log("Execute request with id: $it") }
            }
        )
    }
    single { get<OMServices>().authentication }
    single { get<OMServices>().articles }
    single { get<OMServices>().users }
    single { get<OMServices>().assets }
    single { get<OMServices>().episodes }
    single { get<OMServices>().landingPage }
    single { get<OMServices>().workouts }
    single { get<OMServices>().comments }
    single { get<OMServices>().categories }
    single { get<OMServices>().invoices }
    single { get<OMServices>().teams }
    single { get<OMServices>().texts }
    single { get<OMServices>().vouchers }
    single { get<OMServices>().subscriptions }
    single { get<OMServices>().checkouts }
    single { get<OMServices>().reports }
    single { CurrentUserProvider(get()) }
    factory { UserListViewModel(get()) }
    factory { UserEditViewModel(get(), get()) }
    factory { UserActivityViewModel(get()) }
    factory { UserSubscriptionViewModel(get(), get()) }
    factory { VoucherListViewModel(get()) }
    factory { VoucherEditViewModel(get()) }
    factory { VoucherCheckoutInformationViewModel(get()) }
    factory { ArticleListViewModel(get(), get(), get(), get(), get()) }
    factory { ArticleEditViewModel(get(), get(), get(), get()) }
    factory { CategoryEditViewModel(get()) }
    factory { CommentListViewModel(get()) }
    factory { CommentEditViewModel(get(), get(), get()) }
    factory { AssetListViewModel(get()) }
    factory { EpisodeListViewModel(get()) }
    factory { EpisodeEditViewModel(get()) }
    factory { InvoiceListViewModel(get()) }
    factory { TextListViewModel(get()) }
    factory { TextEditViewModel(get()) }
    factory { LandingEditViewModel(get()) }
    factory { TeamEditViewModel(get()) }
    factory { WorkoutListViewModel(get()) }
    factory { WorkoutEditViewModel(get()) }
    factory { ReportListViewModel(get()) }
}